<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Subscription</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Subscription</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <section class="content">
        <div class="container-fluid">
          <div class="card">
            <div class="card-header">
              <a href="javascript:void(0)" class="btn btn-info" @click="showFilter = !showFilter"><i
                class="fa fa-filter"></i>Filter</a>

              <div class="card-tools">
                <router-link :to="{ name: 'SubscriptionAdd'}" class="btn btn-success">
                  <i class="fas fa-plus"></i> New
                </router-link>
              </div>
            </div>
            <div class="overlay" v-if="is_loading">
                <i class="fas fa-2x fa-sync-alt fa-spin"></i>
            </div>
            <div class="card-body p-0">
              <form @submit.prevent="getList()" method="GET" novalidate>
                <div class="card-body" v-if="showFilter">
                  <div class="row">

                  </div>
                  <div class="row">
                    <div class="col-10 offset-1">
                      <button type="submit" class="btn btn-primary m-2px">Search</button>
                      <button type="reset" class="btn btn-warning" @click="resetFilter()">Reset</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

        <div class="card-body table-responsive-md p-0">
          <table class="table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>User-Id</th>
                    <th>Subscription Plan</th>
                    <th>Base Subscription-Plan</th>
                    <th>Subscribe At</th>
                    <th>Expire At</th>
                    <th>Renew At</th>
                    <th>Notify At</th>
                    <th>Unsubscribe At</th>
                    <th>Subscription Channel</th>
                    <th>Unsubscription Channel</th>
                    <th>Auto Renew</th>
                    <th>App-Id</th>
                <th class="text-right">Action</th>

                </tr>
            </thead>
            <tbody class="list">
                <tr v-for="(val, index) in list.data">

                    <td>{{ val.id }}</td>
                    <td>{{ val.userId }}</td>
                    <td>{{ val.subscriptionPlanId }}</td>
                    <td>{{ val.baseSubscriptionPlanId }}</td>
                    <td>{{ val.subscribeAt }}</td>
                    <td>{{ val.expireAt }}</td>
                    <td>{{ val.renewAt }}</td>
                    <td>{{ val.notifyAt }}</td>
                    <td>{{ val.unsubscribeAt }}</td>
                    <td>{{ val.subChannel }}</td>
                    <td>{{ val.unsubChannel }}</td>
                    <td>{{ val.autoRenew }}</td>
                    <td>{{ val.appId }}</td>
                    <td class="text-right">
                        <router-link v-if="actionView == true" :to="{ name: 'SubscriptionShow', params: { id: val.id }}" class="btn btn-info btn-sm m-2px">
                          <i class="fas fa-eye"></i>
                        </router-link>
                        <router-link v-if="actionEdit == true" :to="{ name: 'SubscriptionEdit', params: { id: val.id }}" class="btn btn-info btn-sm m-2px">
                          <i class="fas fa-pencil-alt"></i>
                        </router-link>
                        <a v-if="actionDelete == true" class="btn btn-danger btn-sm m-2px" href="javascript:void(0)" @click="deleteObject(val.id, index)"><i class="fas fa-trash"></i></a>
                    </td>

                </tr>
            </tbody>
          </table>
          <div class="p-4" v-if="_.isEmpty(list.data) && is_loading == false">
            <div class="alert alert-default-warning" role="alert">
              No data found!
            </div>
          </div>
        </div>
            <div class="card-footer">
              <vue-pagination
                :pagination="list"
                v-if="list.total > list.per_page"
                @paginate="getList()"
              ></vue-pagination>
            </div>
          </div>
        </div>
    </section>
  </div>
</template>

<script>
import VuePagination from "@/components/partials/PaginationComponent";
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "Subscription",
  components: {
    DatePicker, VuePagination
  },
  data: () => ({
    list: {
      total: 0,
      per_page: 1,
      from: 1,
      to: 0,
      current_page: 1
    },
    actionView: false,
    actionEdit: false,
    actionDelete: false,
    api_url: "",
    showFilter: false,
    filter: {},
    dateRange: "",
    pre: [],
    is_loading: false,
    empty: false,
    empty_message: ""
  }),
  mounted: function () {
    this.api_url = "/subscriptions";
    this.getList();
  },
  methods: {
    getList: function () {
      let _this = this;
      _this.is_loading = true;
      return new Promise((resolve, reject) => {
        let params = {page: _this.list.current_page}
        params = {..._this.filter, ...params};
        axios.get(_this.api_url, {
          params: params
        }).then(response => {
          _this.list = response.data;
          resolve(response.data);
        }).catch(error => {
          _this.empty = true;
          _this.empty_message = response.error.message;
          reject(error);
          console.log("error", error.response.data);
        }).finally(() => {
          _this.is_loading = false;
        })
      });
    },
    resetFilter() {
      this.list.current_page = 1;
      this.filter = {};
      this.getList();
    },
    deleteObject: function (id, index) {
      let _this = this;
      let delete_api_url = "/subscriptions/"+id;

      _this.$swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then((result) => {
        if (result.isConfirmed) {
          _this.is_loading = true;
          axios.delete(delete_api_url)
            .then(response => {
              _this.list.data.splice(index, 1);
              _this.$toaster.success(response.data.message);
              _this.is_loading = false;
            })
            .catch(error => {
              _this.is_loading = false;
              console.log(error);
              _this.$swal.fire(
                "Failed!",
                "failed to delete.",
                "error"
              )
            })
            .finally(() => {
              _this.is_loading = false;
            });
        }
      });
    }
  }
}
</script>
